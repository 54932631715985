import config from 'config'
import cookie from 'cookie'
import qs from 'qs'
import { setLang } from 'redux/reducers/search'
import crypto from 'crypto'
import timestamp from 'time-stamp'
import stringifyObject from 'stringify-object'
const { v4: uuidv4 } = require('uuid')
const regexpWordDivider = /\s|,|\(|\)|\.|:|;|\/|'|"/
const maxRecursion = 100

export const getLanguage = (mnemonic) => {
  return config.languages.find(language => language.mnemonic === mnemonic)
}

export const getPossibleLanguages = () => {
  return config.languages.map(language => language.mnemonic)
}

export const getSearchableLanguages = (userLanguages) => {
  return getPossibleLanguages().filter(lang => userLanguages && userLanguages.some(language => language.mnemonic === lang || ((lang === 'nb' || lang === 'nn') && language.mnemonic === 'no')))
}

export const tibetUrl = (path, tai, lang = 'no', returnTo = process.env.NEXT_PUBLIC_URL) => {
  const returnUrl = returnTo
  return `/tilgang?path=${path}&site=${process.env.NEXT_PUBLIC_TIBETSITE}&tibet_access_identifier=${tai}&return_to=${encodeURIComponent(returnUrl)}&locale=${(lang === 'en') ? 'en' : 'nb_NO'}&details=true`
  // return `${process.env.NEXT_PUBLIC_TIBETSERVICE}${path}?site=${process.env.NEXT_PUBLIC_TIBETSITE}&tibet_access_identifier=${tai}&return_to=${encodeURIComponent(returnUrl)}&locale=${(lang === 'en') ? 'en' : 'nb_NO'}&details=true`
}

export const signedTibetUrl = (options) => {
  const baseString = tibetSignatureBaseString(options)
  const base64 = crypto.createHmac('sha1', process.env.TIBETSECRET).update(baseString).digest('base64')
  const url = tibetUrlWithSignature(options, base64)
  return url
}

export function tibetSignatureBaseString (options) {
  const scheme = options.scheme
  const baseStringUri = scheme.toLowerCase() + '://' + options.hostname.toLowerCase() + options.path
  const encodedParameters = []
  const parameters = options.parameters
  for (const key in parameters) {
    if (parameters[key] !== undefined) {
      encodedParameters.push(rfc3986Encode(key) + '=' + rfc3986Encode(parameters[key]))
    }
  }
  encodedParameters.sort()
  const normalizedParameters = encodedParameters.join('&')

  return options.method.toUpperCase() +
      '&' + rfc3986Encode(baseStringUri) +
      '&' + rfc3986Encode(normalizedParameters)
}

export function tibetUrlWithSignature (options, base64) {
  const queryString = qs.stringify(options.parameters)
  return `${process.env.NEXT_PUBLIC_TIBETSERVICE}${options.path}?${queryString}&signature=${rfc3986Encode(base64)}`
}

export function rfc3986Encode (str) {
  return encodeURIComponent(str).replace(/!/g, '%21').replace(/\*/g, '%2A').replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/'/g, '%27')
}

export const assureCorrectLang = async (store, isServer, req, res) => {
  const lang = getCookieLang(isServer, req, res)
  await store.dispatch(setLang(lang))
}

export const getCookieLang = (isServer, req, res) => {
  let lang = 'en'
  if (isServer) {
    if (req.cookies && req.cookies.skole_lang) {
      lang = req.cookies.skole_lang
    }
  } else if (cookie.parse(document.cookie).skole_lang) {
    lang = cookie.parse(document.cookie).skole_lang
  }
  return lang
}

export const adjustStoredUserLevels = (userLevels, languages) => {
  const adjustedUserLevels = getSearchableLanguages(languages).map(language => {
    const storedLevel = userLevels.find(level => level.mnemonic === language)
    return { mnemonic: language, level: storedLevel ? storedLevel.level : 1 }
  })
  return adjustedUserLevels
}

export const levelResultHasHits = (levelResult) => {
  return levelResult && levelResult.result && levelResult.result.meta && levelResult.result.meta.found > 0
}

const findSearchMode = (levelResult) => {
  return levelResult.result && levelResult.result.meta && levelResult.result.meta.lastTriedSearchMode ? levelResult.result.meta.lastTriedSearchMode : 99
}

export const findAltHitLevels = (levelResults, hitLevel, bestSearchMode) => {
  if (bestSearchMode === 99) {
    return []
  } else {
    return levelResults.filter(levelResult => findSearchMode(levelResult) === bestSearchMode && ((levelResult.level - 1) !== hitLevel)).map(levelResult => levelResult.level - 1)
  }
}

export const findBestSearchmode = (levelResults) => {
  const searchModes = levelResults.map(levelResult => findSearchMode(levelResult))
  return Math.min(...searchModes)
}

export const findHitLevel = (levelResults, currentLevel) => {
  const bestSearchMode = findBestSearchmode(levelResults)
  const currentLevelSearchMode = findSearchMode(levelResults[currentLevel - 1])
  if (currentLevelSearchMode === bestSearchMode || bestSearchMode === 99) {
    return currentLevel - 1
  } else {
    return levelResults.findIndex(levelResult => findSearchMode(levelResult) === bestSearchMode)
  }
}

export const computePath = (inPath) => {
  const queryString = document.location.search
  const fullPath = inPath.includes('?') ? inPath : inPath + queryString
  return decodeURIComponent(fullPath)
}

export const formatHeaders = ({ headers, isServer = false } = {}) => {
  const formattedHeaders = {}
  if (headers.xForwardedFor) {
    formattedHeaders['X-Forwarded-For'] = headers.xForwardedFor
  }
  if (headers.referer && isServer) {
    formattedHeaders.referer = headers.referer
  }
  if (!isServer) {
    if (document.cookie && cookie.parse(document.cookie).skole_tai) {
      formattedHeaders.Authorization = cookie.parse(document.cookie).skole_tai
    } else {
      const newTai = uuidv4()
      document.cookie = cookie.serialize('skole_tai', newTai, { maxAge: 365 * 24 * 60 * 60 * 1000 })
      formattedHeaders.Authorization = newTai
    }
  } else {
    if (headers.tai) {
      formattedHeaders.Authorization = headers.tai
    }
  }
  return formattedHeaders
}

export const reloadOnServerChange = (releaseNo, query, pathname, publicationAccessChanged) => {
  if (publicationAccessChanged || (releaseNo && releaseNo !== config.releaseNo)) {
    const url = `${config.url}${pathname}?${qs.stringify(query)}`
    window.location = url
  }
}

export const containsPhrase = (node, phrase) => {
  const normalizedPhrase = phrase ? phrase.trim().replace(/\s+/, ' ') : ''
  if (!node || node.type !== 'text' || !normalizedPhrase) {
    return false
  }
  const parts = normalizedPhrase.split(/ /)
  const numPartsContained = containsWord(node.data, parts, 0, 0)
  return numPartsContained >= parts.length
}

const containsWord = (text, parts, numPartsContained, callCount) => {
  if (parts.length < 1 || callCount > maxRecursion) {
    return numPartsContained
  }
  const part = parts[0]
  const partRegex = new RegExp(escapeStringRegexp(part), 'i')
  const match = text.match(partRegex)
  const pos = match ? match.index : -1
  const remainingParts = (pos > -1 && !isSeparateWord(part, text, pos)) ? parts.slice(0) : parts.slice(1)
  const numFound = (pos > -1 && isSeparateWord(part, text, pos)) ? 1 : 0
  return containsWord(text.substring(pos + part.length), remainingParts, numPartsContained + numFound, callCount + 1)
}

export const isSeparateWord = (part, text, pos) => {
  const endPos = pos + part.length - 1
  return ((pos === 0 || regexpWordDivider.test(text.substr(pos - 1, 1))) &&
      (endPos === text.length - 1 || regexpWordDivider.test(text.substr(endPos + 1, 1))))
}

export function getXForwardedFor (req) {
  if (req['X-Forwarded-For']) {
    return req['X-Forwarded-For']
  } else if (req.headers && req.headers['x-forwarded-for']) {
    return req.headers['x-forwarded-for']
  } else if (req.ip) {
    const ip = req.ip.startsWith('::ffff:') ? req.ip.substr(7) : req.ip
    return ip
  } else if (req.headers && req.headers.host && req.headers.host.includes('localhost')) {
    // localhost
    return '127.0.0.1'
  } else {
    return undefined
  }
}

export function getReferer (req) {
  if (req.referer) {
    return req.referer
  } else if (req.headers && req.headers.referer) {
    return req.headers.referer
  } else {
    return undefined
  }
}

export function getSlugString (slug) {
  if (Array.isArray(slug)) {
    return slug.join('/')
  } else {
    return slug
  }
}

export const datetimeStamp = () => {
  return timestamp('YYYY-MM-DD:HH:mm:ss')
}

export const objectToLine = (errorObject) => {
  return stringifyObject(errorObject).replace(/\s+/g, ' ')
}

export function getHeadersForTiBet (req) {
  const headers = {}
  if (req.headers['x-forwarded-for']) {
    headers['X-Forwarded-For'] = req.headers['x-forwarded-for']
  }
  if (req.headers.referer) {
    headers.referer = req.headers.referer
  }
  if (req.headers.authorization) {
    headers.Authorization = req.headers.authorization
  }
  return headers
}

export function getPubsForLang (lang) {
  const langConfig = lang ? config.searchconfig[lang] : undefined
  if (langConfig) {
    const pubArrays = langConfig.map(level => level.pubs)
    const flattenedPubs = pubArrays.reduce(
      (acc, cur) => acc.concat(cur),
      []
    )
    return flattenedPubs.join(',')
  } else {
    return ''
  }
}

export function escapeStringRegexp (input) {
  // local version of escape-string-regexp, to avoid IE11 syntax error
  if (typeof input !== 'string') {
    throw new TypeError('Expected a string')
  }

  // Escape characters with special meaning either inside or outside character sets.
  // Use a simple backslash escape when it’s always valid, and a \unnnn escape when the simpler form would be disallowed by Unicode patterns’ stricter grammar.
  return input
    .replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
    .replace(/-/g, '\\x2d')
}

export function createSingleton (name, create) {
  const s = Symbol.for(name)
  let scope = global[s]
  if (!scope) {
    scope = { ...create() }
    global[s] = scope
  }
  return scope
}
