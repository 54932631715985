import apiClient from '../../utils/apiClient'

const searchInitialState = {
  level: 1,
  currentLevel: 1,
  levelResults: [],
  searchPhrase: '',
  queryPhrase: '',
  visibleInflections: [],
  isIdSearch: false,
  lang: 'en',
  selection: null,
  scrollToEle: null,
  elementRefid: null,
  artId: null,
  releaseNo: '',
  publicationAccessChanged: false
}

export const actionTypes = {
  SEARCH: 'SEARCH',
  CHANGE_CURRENT_LEVEL: 'CHANGE_CURRENT_LEVEL',
  HIDE_INFLECTION: 'HIDE_INFLECTION',
  SHOW_INFLECTION: 'SHOW_INFLECTION',
  SET_SEARCH_PHRASE: 'SET_SEARCH_PHRASE',
  SET_LANG: 'SET_LANG',
  GET_USER_STACKS: 'GET_USER_STACKS',
  SET_SELECTION: 'SET_SELECTION',
  SET_SCROLL_TO_ELE: 'SET_SCROLL_TO_ELE'
}

export default function reducer (state = searchInitialState, action) {
  switch (action.type) {
    case actionTypes.SEARCH:
      return {
        ...state,
        levelResults: action.levelResults,
        searchPhrase: action.phrase,
        queryPhrase: action.phrase,
        lang: action.lang,
        currentLevel: action.defaultLevel,
        visibleInflections: [],
        isIdSearch: action.isIdSearch,
        selection: null,
        elementRefid: action.elementRefid,
        artId: action.artId,
        releaseNo: action.releaseNo || '',
        publicationAccessChanged: action.publicationAccessChanged
      }
    case actionTypes.CHANGE_CURRENT_LEVEL:
      return Object.assign({}, state, {
        currentLevel: action.currentLevel
      })
    case actionTypes.SHOW_INFLECTION:
      return {
        ...state,
        visibleInflections: [
          ...state.visibleInflections,
          action.toggleId
        ]
      }
    case actionTypes.HIDE_INFLECTION:
      return {
        ...state,
        visibleInflections: state.visibleInflections.filter((id) => id !== action.toggleId)
      }
    case actionTypes.SET_SEARCH_PHRASE:
      return {
        ...state,
        searchPhrase: action.searchPhrase
      }
    case actionTypes.SET_LANG:
      return {
        ...state,
        lang: action.lang
      }
    case actionTypes.SET_SELECTION:
      return {
        ...state,
        selection: action.selection
      }
    case actionTypes.SET_SCROLL_TO_ELE:
      return {
        ...state,
        scrollToEle: action.ref
      }
    default: return state
  }
}

export const search = (lang, phrase, pub, articleRefid, elementRefid, artId, nynorsk, headers, defaultLevel, userId, uuid, accessProvidingUsers) => dispatch => {
  const translate = nynorsk ? 2 : undefined
  const isIdSearch = !!(articleRefid || elementRefid || artId)
  if ((phrase && lang) || isIdSearch) {
    return apiClient.get('/search', {
      params: {
        phrase,
        language: lang,
        pub,
        articleRefid,
        elementRefid,
        artId,
        translate,
        userId,
        uuid,
        accessProvidingUsers
      },
      headers: { ...headers }
    })
      .then(({ data }) => {
        dispatch({
          type: actionTypes.SEARCH,
          levelResults: data.levelResults,
          releaseNo: data.releaseNo,
          phrase,
          lang: data.language,
          isIdSearch,
          elementRefid,
          defaultLevel,
          artId,
          publicationAccessChanged: data.publicationAccessChanged
        })
      })
  } else {
    dispatch({
      type: actionTypes.SEARCH,
      levelResults: [],
      phrase,
      lang,
      isIdSearch,
      defaultLevel
    })
  }
}

export const changeCurrentLevel = (currentLevel, lang) => dispatch => {
  return dispatch({
    type: actionTypes.CHANGE_CURRENT_LEVEL,
    currentLevel,
    meta: {
      analytics: {
        category: 'Søk',
        action: 'Nivåendring',
        label: `${lang} ${currentLevel}`
      }
    }
  })
}

export const hideInflection = (toggleId) => dispatch => {
  return dispatch({ type: actionTypes.HIDE_INFLECTION, toggleId })
}

export const showInflection = (toggleId) => dispatch => {
  return dispatch({ type: actionTypes.SHOW_INFLECTION, toggleId })
}

export const setSearchPhrase = (searchPhrase) => dispatch => {
  return dispatch({ type: actionTypes.SET_SEARCH_PHRASE, searchPhrase })
}

export const setLang = (lang) => dispatch => {
  return dispatch({ type: actionTypes.SET_LANG, lang })
}

export const setSelection = (selection = null) => dispatch => {
  return dispatch({ type: actionTypes.SET_SELECTION, selection })
}

export const setScrollToEle = (ref = null) => dispatch => {
  return dispatch({ type: actionTypes.SET_SCROLL_TO_ELE, ref })
}
