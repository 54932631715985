import { event } from 'utils/analytics'

const Analytics = ({ dispatch, getState }) => next => action => {
  const { analytics } = action.meta || {}
  next(action)
  if (analytics && analytics.category && analytics.action) {
    event(analytics)
  }
}

export default Analytics
