import { datetimeStamp, getHeadersForTiBet } from './utils'
import stringify from 'json-stringify-safe'

export function logReduxError (error, location) {
  console.error(`${datetimeStamp()} ${location}; error: ${error}`)
}

export function logApiError (error, req) {
  const msg = error.message || 'Unknown error'
  const baseURL = req.protocol + '://' + req.headers.host + '/'
  const reqUrl = new URL(req.url, baseURL)
  const tibetHeaders = getHeadersForTiBet(req)
  console.error(`${datetimeStamp()} ${req.method} ${reqUrl.pathname}; query: ${reqUrl.search}; headers: ${stringify(tibetHeaders, null)}; err: ${stringify(msg, null)}`)
}

export function logPageError (error, req, context, query) {
  const msg = error.Error || 'Unknown error'
  const tibetHeaders = req ? getHeadersForTiBet(req) : 'no req'
  console.error(`${datetimeStamp()} ${error.status} 'GET' ${context}; query: ${stringify(query, null)}; headers: ${stringify(tibetHeaders, null)}; err: ${stringify(msg, null)}`)
  console.error(`${datetimeStamp()} ${error}`)
}

export function logError (error, context) {
  console.error(`${datetimeStamp()} ${error.status} ${context} ${error.message}`)
  console.error(`${datetimeStamp()} ${error}`)
}

export function logSearch (phrase, language, pub, articleRefid, elementRefid, artId, translate, userId, counts) {
  console.log(`${datetimeStamp()} language:${language};phrase:${phrase};pub:${pub};articleRefid:${articleRefid};elementRefid:${elementRefid};artId:${artId};translate:${translate};userId:${userId};counts:${counts}`)
}
