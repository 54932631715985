import React from 'react'
import { Provider } from 'react-redux'
import App from 'next/app'
import Head from 'next/head'
import Script from 'next/script'
import withRedux from 'next-redux-wrapper'
import { initStore } from 'store'
import { loadUser } from 'redux/reducers/user'
import { setXForwardedFor, setReferer } from 'redux/reducers/headers'
import Layout from 'components/Layout'
import { assureCorrectLang, formatHeaders, reloadOnServerChange, getXForwardedFor, getReferer } from 'utils/utils'
import config from 'config'
import { parse } from 'next-useragent'
import { ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import theme from '../styles/theme'

class OrdnettSkole extends App {
  static async getInitialProps ({ Component, ctx }) {
    const xForwardedFor = ctx.req ? getXForwardedFor(ctx.req) : undefined
    const referer = ctx.req ? getReferer(ctx.req) : undefined
    if (xForwardedFor) {
      ctx.store.dispatch(setXForwardedFor(xForwardedFor))
    }
    if (referer) {
      ctx.store.dispatch(setReferer(referer))
    }
    const headers = formatHeaders({ headers: ctx.store.getState().headers, isServer: ctx.isServer })
    const redirectFromTibet = ctx.query.redirect_from_tibet
    const accessKey = ctx.query.access_key
    await ctx.store.dispatch(loadUser(headers, redirectFromTibet, accessKey, ctx.isServer))
    await assureCorrectLang(ctx.store, ctx.isServer, ctx.req, ctx.res)
    const pageProps = await (Component.getInitialProps ? Component.getInitialProps(ctx) : {})
    const cmsApiReleaseNo = pageProps ? pageProps.releaseNo : ''
    const searchApiReleaseNo = ctx.store.getState().search ? ctx.store.getState().search.releaseNo : ''
    const releaseNo = cmsApiReleaseNo || searchApiReleaseNo
    const publicationAccessChanged = ctx.store.getState().search ? ctx.store.getState().search.publicationAccessChanged : false
    if (config.isProduction && !ctx.isServer) {
      reloadOnServerChange(releaseNo, ctx.query, ctx.pathname, publicationAccessChanged)
    }
    const returnValue = {
      pageProps: {
        ...(Component.getInitialProps ? await Component.getInitialProps(ctx) : {})
      }
    }
    const userAgent = typeof window === 'undefined' ? parse(ctx.req.headers['user-agent']) : (window.navigator.userAgent)
    return { ...returnValue, userAgent }
  }

  render () {
    const { Component, pageProps, store, userAgent } = this.props
    const loadPolyfill = userAgent?.isIE || (userAgent?.isEdge && userAgent?.browserVersion < 14) || (userAgent?.isSafari && (userAgent?.browserVersion < 10))
    return (
      <Provider store={store}>
        <Head>
          <meta name='viewport' content='width=device-width, initial-scale=1' />
        </Head>
        {
          loadPolyfill && (
            <Script
              src='/scripts/babel-polyfill/7.2.5/polyfill.min.js'
              strategy='beforeInteractive'
            />
          )
        }
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </ThemeProvider>
      </Provider>
    )
  }
}

export default withRedux(initStore)(OrdnettSkole)
