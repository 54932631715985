module.exports = Object.assign({
  'primary': '#13b5ea',
  'primary50': '#e0f5fc',
  'primary100': '#b1e5f6',
  'primary200': '#7dd3f1',
  'primary300': '#48c2ec',
  'primary400': '#13b5ea',
  'primary500': '#00a8e8',
  'primary600': '#009ada',
  'primary700': '#0088c7',
  'primary800': '#0077b3',
  'primary900': '#005793',
  'secondary': '#003a63',
  'secondary50': '#e2eff4',
  'secondary100': '#b7d6e4',
  'secondary200': '#8dbdd3',
  'secondary300': '#66a3c2',
  'secondary400': '#4b92b8',
  'secondary500': '#3181ae',
  'secondary600': '#2675a3',
  'secondary700': '#196593',
  'secondary800': '#0f5582',
  'secondary900': '#003a63',
  'surface': '#ffffff',
  'light': '#ffffff',
  'dark': '#f5f5f5',
  'blackText': '#212121',
  'blackTextMedium': '#616161',
  'blackTextDisabled': '#abadb0',
  'whiteText': '#fff',
  'whiteTextMedium': '#919191',
  'whiteTextDisabled': '#595959',
  'error': '#c51162',
  'green': '#94c64e',
  'pink': '#C5299B',
  'purple': '#662e89',
  'orange': '#f89728',
  'seaGreen': '#00b1b0',
  'link': '#20399D',
  'linkHover': '#BF1722',
  'highlight': '#faebcc'
})
