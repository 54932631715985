const SET_X_FORWARDED_FOR = 'ordnett/headers/SET_X_FORWARDED_FOR'
const SET_REFERER = 'ordnett/headers/SET_REFERER'

export const headersInitialState = {
  tai: '',
  xForwardedFor: undefined,
  referer: undefined
}

export default function reducer (state = headersInitialState, action) {
  switch (action.type) {
    case SET_X_FORWARDED_FOR:
      return {
        ...state,
        xForwardedFor: action.xForwardedFor
      }
    case SET_REFERER:
      return {
        ...state,
        referer: action.referer
      }
    default:
      return state
  }
}

export const setXForwardedFor = (xForwardedFor) => dispatch => {
  dispatch({
    type: SET_X_FORWARDED_FOR,
    xForwardedFor
  })
}

export const setReferer = (referer) => dispatch => {
  dispatch({
    type: SET_REFERER,
    referer
  })
}
