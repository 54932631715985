import React from 'react'
import { trackPageview } from 'utils/analytics'
import Router from 'next/router'

const GaPageTracker = (WrappedComponent) => (
  class GaWrapper extends React.Component {
    componentDidMount () {
      trackPageview()
      Router.router.events.on('routeChangeComplete', trackPageview)
    }

    componentWillUnmount () {
      Router.router.events.off('routeChangeComplete', trackPageview)
    }

    render () {
      return (
        <WrappedComponent {...this.props} />
      )
    }
  }
)

export default GaPageTracker
