import { combineReducers } from 'redux'

import headers from 'redux/reducers/headers'
import user from 'redux/reducers/user'
import search from 'redux/reducers/search'

export default combineReducers({
  headers,
  user,
  search
})
