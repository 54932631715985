import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LogoGyldendal from 'components/logos/Gyldendal'
import OrdnettTech from 'components/logos/OrdnettTech'
import colors from 'styles/colors'

class Footer extends React.Component {
  render () {
    const { lang, searchPhrase } = this.props
    const dictUrl = searchPhrase !== '' ? '/om-ordbokene#' + lang : '/om-ordbokene'
    return (
      <footer>
        <div className='container'>
          <p><Link href='/[...slug]' as={dictUrl}><a>Om ordbøkene</a></Link></p>
        </div>
        <nav className='container'>
          <ul>
            <li><Link href='/[...slug]' as='/kontakt'><a>Kontakt oss</a></Link></li>
            <li><Link href='/[...slug]' as='/informasjonskapsler'><a>Om informasjonskapsler</a></Link></li>
            <li><Link href='/gyldendal/site/membership_terms'><a>Brukervilkår</a></Link></li>
            <li><a href='https://www.gyldendal.no/om-gyldendal/personvern/gyldendal-personvernerklaering/'>Personvernerklæring</a></li>
          </ul>
        </nav>
        <div className='footer-logos'>
          <a href='https://gyldendal.no' className='logo-link kf-link'><LogoGyldendal color1='#616161' color2='#616161' maxWidth='260px' /></a>
          <a href='https://www.ordnett.no' className='logo-link ordnett-link'><OrdnettTech color1='#616161' color2='#616161' maxWidth='260px' /></a>
        </div>
        <div className='container'>
          <span className='extraSmall'>Versjon {process.env.NEXT_PUBLIC_RELEASE_NO}</span>
        </div>
        <style jsx>{`
          footer {
            flex: none;
            color: ${colors.blackTextMedium};
            text-align: center;
            padding-top: 2rem;
          }
          footer a {
            color: ${colors.blackTextMedium};
          }
          .kf-link {
            height: 80px;
            width: 300px;
            display: block;
            margin: 1rem;
          }
          .ordnett-link {
            height: 80px;
            width: 160px;
            display: block;
            margin: 1rem;
          }
          footer > nav > ul > li {
            display: inline;
            margin: 0 .5rem;
          }
          .footer-logos {
            vertical-align: bottom;
            margin: 1rem auto;
            display: flex;
            justify-content: center;
            width: 100%;
            flex-wrap: wrap;
          }
          img.logo {
            height: 5rem;
            margin: 1rem;
            vertical-align: top;
          }
          span.extraSmall {
            font-size: 0.8rem;
          }
          @media only screen and (max-width: 600px) {
            footer > nav > ul > li {
              display: block;
              margin: 0 .5rem;
            }
          }
        `}
        </style>
      </footer>
    )
  }
}

Footer.propTypes = {
  lang: PropTypes.string,
  searchPhrase: PropTypes.string
}

const mapStateToProps = ({ search: { lang, searchPhrase } }) => ({ lang, searchPhrase })
export default connect(mapStateToProps)(Footer)
