import ReactGA from 'react-ga'
const IS_BROWSER = typeof window !== 'undefined'
// const debug = process.env.NODE_ENV !== 'production'
const debug = false

export const trackPageview = (path = document.location.pathname) => {
  const queryString = document.location.search
  const fullPath = path.includes('?') ? path : path + queryString
  const pathDec = decodeURIComponent(fullPath)
  const pathTrimmed = pathDec.replace(/\?.*?$/, '')
  const title = pathTrimmed !== '/' ? pathTrimmed.substr(1).split('/').join(' - ') : 'forside'
  initGa()
  ReactGA.set({ title: title })
  ReactGA.pageview(pathDec)
}

export const initGa = () => {
  if (IS_BROWSER && !window.GA_INITIALIZED) {
    ReactGA.initialize(process.env.NEXT_PUBLIC_GA_CODE, { debug })
    window.GA_INITIALIZED = true
  }
}

export const event = (analytics) => {
  if (analytics.category && analytics.action) {
    initGa()
    ReactGA.event(analytics)
  }
}
