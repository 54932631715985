import { createTheme } from '@mui/material/styles'

const primary = '#13b5ea'
const secondary = '#003a63'
const info = '#CA3092'
const success = '#a0cf67'
const link = '#005793'
const favorite = '#F89728'

// Create a theme instance.
const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: primary
    },
    secondary: {
      main: secondary
    },
    info: {
      main: info
    },
    success: {
      main: success
    },
    favorite: {
      main: favorite
    }
  },
  typography: {
    fontSize: 16,
    h1: {
      fontSize: '2rem',
      fontWeight: 700,
      marginBottom: '.625rem',
      '@media (min-width: 600px)': {
        fontSize: '3rem'
      }
    },
    h2: {
      fontSize: '1.25rem',
      fontweight: 700,
      marginBottom: '.3125rem',
      '@media (min-width: 600px)': {
        fontSize: '1.5rem'
      }
    },
    h3: {
      fontSize: '1.125rem',
      fontWeight: 700,
      marginBottom: '.3125rem',
      '@media (min-width: 600px)': {
        fontSize: '1.25rem'
      }
    },
    h4: {
      fontSize: '1rem',
      fontWeight: 700,
      marginBottom: '.3125rem',
      '@media (min-width: 600px)': {
        fontSize: '1.125rem'
      }
    },
    body1: {
      fontSize: '1rem',
      '@media (min-width: 600px)': {
        fontSize: '1.125rem'
      }
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        },
        outlinedPrimary: {
          color: secondary,
          borderColor: secondary
        },
        textPrimary: {
          color: secondary
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: secondary
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: link
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 700,
            color: secondary
          }
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: 300
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        thumb: {
          color: secondary
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: secondary
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontWeight: 300
        }
      }
    }
  }
})

export default theme
