import React from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import HelpIcon from '@mui/icons-material/Help'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import PhoneIcon from '@mui/icons-material/Phone'
import Drawer from '@mui/material/Drawer'
import Divider from '@mui/material//Divider'
import IconButton from '@mui/material/IconButton'

import SearchForm from 'components/SearchForm'
import Button from 'components/Button'

import colors from 'styles/colors'

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showUserMenu: false,
      showDrawer: false
    }
  }

  handleToggleShowDrawer = () => {
    this.setState({
      showDrawer: !this.state.showDrawer
    })
  }

  render () {
    const { user } = this.props
    return (
      <header>
        <div className='container top-container'>
          <div className='desktop-navigation'>
            <nav className='desktop-nav' role='navigation'>
              <ul>
                {!!user.loggedInAccessKeyUser && <li><span className='access-key'>Tilgang via {user.accessKeyName}</span></li>}
                <li><Link href='/[...slug]' as='/kontakt'><a><PhoneIcon style={{ height: '1.6rem', width: '1.6rem', verticalAlign: '-7px', marginRight: '.3rem', color: colors.secondary }} />Kontakt oss</a></Link></li>
                {user.isLoggedInUser && <li><Link href='/sprakverktoy'><a><LibraryBooksIcon style={{ height: '1.6rem', width: '1.6rem', verticalAlign: '-7px', marginRight: '.3rem', color: colors.secondary }} />Språkverktøy</a></Link></li>}
                <li><Link href='/[...slug]' as='/hjelp'><a><HelpIcon style={{ height: '1.6rem', width: '1.6rem', verticalAlign: '-7px', marginRight: '.3rem', color: colors.secondary }} />Hjelp</a></Link></li>
                {user.isLoggedInUser && <li><Link href='/minside'><a><AccountCircleIcon style={{ height: '1.6rem', width: '1.6rem', verticalAlign: '-7px', marginRight: '.3rem', color: colors.secondary }} />{user.loggedInUser.first_name} {user.loggedInUser.last_name}</a></Link></li>}
                {!user.isLoggedInUser && <li><Link href='/gyldendal/access/login'><a><AccountCircleIcon style={{ height: '1.6rem', width: '1.6rem', verticalAlign: '-7px', marginRight: '.3rem', color: colors.secondary }} />Logg inn</a></Link></li>}
              </ul>
            </nav>
          </div>
          <div className='mobile-navigation'>
            <IconButton aria-label='Vis meny' size='large'>
              <MenuIcon fontSize='large' style={{ color: colors.secondary }} />
            </IconButton>
          </div>
          <Drawer open={this.state.showDrawer} onClose={this.handleToggleShowDrawer}>
            <div className='drawer-header'>
              Meny
            </div>
            <nav className='drawer-nav' role='navigation'>
              <ul>
                {!!user.loggedInAccessKeyUser && <li><span>Tilgang via {user.accessKeyName}</span></li>}
                {user.isLoggedInUser && <li><Link href='/minside'><a><AccountCircleIcon style={{ height: '1.6rem', width: '1.6rem', verticalAlign: '-5px', marginRight: '.3rem', color: colors.secondary }} />{user.loggedInUser.first_name} {user.loggedInUser.last_name}</a></Link></li>}
                {!user.isLoggedInUser && <li><Link href='/gyldendal/access/login'><a><AccountCircleIcon style={{ height: '1.6rem', width: '1.6rem', verticalAlign: '-5px', marginRight: '.3rem', color: colors.secondary }} />Logg inn</a></Link></li>}
                {user.isLoggedInUser && <li><Link href='/sprakverktoy'><a><LibraryBooksIcon style={{ height: '1.6rem', width: '1.6rem', verticalAlign: '-7px', marginRight: '.3rem', color: colors.secondary }} />Språkverktøy</a></Link></li>}
                <li><Link href='/[...slug]' as='/kontakt'><a><PhoneIcon style={{ height: '1.6rem', width: '1.6rem', verticalAlign: '-7px', marginRight: '.3rem', color: colors.secondary }} />Kontakt oss</a></Link></li>
                <li><Link href='/[...slug]' as='/hjelp'><a><HelpIcon style={{ height: '1.6rem', width: '1.6rem', verticalAlign: '-5px', marginRight: '.3rem', color: colors.secondary }} />Hjelp</a></Link></li>
              </ul>
            </nav>
            <Divider />
            <div className='drawer-footer'>
              <Button onClick={this.handleToggleShowDrawer}><CloseIcon style={{ verticalAlign: '-4px', height: '1.2rem', width: '1.2rem', marginRight: '.2rem' }} />Lukk meny</Button></div>
          </Drawer>
        </div>
        <div className='container header-container'>
          <div>
            <Link href='/'>
              <a className='ordnettskole-logo' aria-label='Gå til forsiden'>
                <img src='/img/ordnett-skole-logo.svg' alt='skole.ordnett.no' className='logo' />
              </a>
            </Link>
          </div>
          <SearchForm />
        </div>
        <style jsx>{`
          .header-container {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: .3rem;
          }
          .ordnettskole-logo img{
            height: 4rem;
          }
          .desktop-navigation {
            display: flex;
            width: 100%;
            justify-content: flex-end;
          }
          .mobile-navigation {
            display: none;
          }
          .desktop-nav ul {
            display: flex;
            margin: 0;
            padding: 0;
          }
          .desktop-nav li {
            padding: .2rem 0 0 1rem;
            line-height: 1.6rem;
          }
          .access-key {
            vertical-align: -1px;
          }
          .drawer-nav ul {
            padding: .5rem;
            margin: 0;
          }
          .drawer-nav li {
            padding: .5rem;
          }
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }
          li {
            
          }
          a {
            text-decoration: none;
            color: #000;
            background-image: none;
          }
          a:hover {
            text-decoration: underline;
          }
          .drawer-header {
            text-align: center;
            font-size: 1.2rem;
            font-weight: 400;
            background-color: ${colors.secondary};
            color: #fff;
            padding: .5rem 0;
          }
          .drawer-footer {
            padding: .5rem 1rem;
            text-align: center;
          }
          .drawer-footer button {
            border: none;
            text-transform: none;
            font-size: 1rem;
            padding: 0; 
          }
          .menu-button {
            background-color: transparent;
          }
          @media (max-width: 1200px) {
            .ordnettskole-logo img{
              height: 4rem;
              /* margin-top: .8rem; */
            }
            .header-container {
              padding: 0 .3rem;
            }
            .top-container {
              padding-right: .3rem;
            }
          }
          @media (max-width: 550px) {
            .top-container {
              position: relative;
              float: right;
              width: 41px;
              height: 41px;
              z-index: 10;
            }
            .desktop-navigation {
              display: none;
            }
            .mobile-navigation {
              display: block;
              margin-right: 1rem;
            }
            .menu-button {
              border: none;
              padding: 0;
            }
            .header-container {
              flex-direction: column;
              justify-content: flex-start;
              position: relative;
              top: -41px;
            }
            .ordnettskole-logo img {
              margin-top: .3rem;
            }
          }
        `}</style>
      </header>
    )
  }
}

Header.propTypes = {
  user: PropTypes.object,
  tai: PropTypes.string
}

const mapStateToProps = ({ headers: { tai }, user: { user } }) => ({ tai, user })
export default connect(mapStateToProps)(Header)
