import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { parseCookies, setCookie } from 'nookies'
import { v4 as uuidv4 } from 'uuid'
import analyticsMiddleware from './redux/middleware/analytics'
import reducer from './redux/reducers/index'
import { headersInitialState } from './redux/reducers/headers'

export const initStore = (initialState = { headers: headersInitialState }, ctx) => {
  const myCookies = parseCookies(ctx)
  const oldTai = myCookies.skole_tai ? myCookies.skole_tai : undefined
  const newTai = uuidv4()
  const tai = oldTai || newTai
  setCookie(ctx, 'skole_tai', tai, {
    maxAge: 365 * 24 * 60 * 60 * 1000,
    path: '/'
  })
  const initialStates = ctx.req ? { ...initialState, headers: { ...headersInitialState, tai } } : { ...initialState }
  const middleware = [thunkMiddleware, analyticsMiddleware]
  const store = createStore(reducer, initialStates, composeWithDevTools(applyMiddleware(...middleware)))
  return store
}
