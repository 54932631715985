import React from 'react'
import PropTypes from 'prop-types'

import colors from 'styles/colors'

const Button = ({ type = 'button', className = '', children, ...rest }) => {
  return (
    <button
      type={type}
      className={className}
      {...rest}
    >
      {children}
      <style jsx>{`
        button {
          background: transparent;
          border: none;
          border-radius: 2px;
          color: rgb(0,0,0);
          position: relative;
          height: 36px;
          margin: 0;
          min-width: 64px;
          padding: 0 16px;
          display: inline-block;
          overflow: hidden;
          will-change: box-shadow;
          transition: background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
                      color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
          outline: none;
          cursor: pointer;
          text-decoration: none;
          text-align: center;
          line-height: 36px;
          vertical-align: middle;
          font-weight: 600;
          font-size: 18px;
          box-sizing: border-box;
          box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
              0 3px 1px -2px rgba(0, 0, 0, 0.2),
              0 1px 5px 0 rgba(0, 0, 0, 0.12);
        }

        button::-moz-focus-inner {
          border: 0;
        }

        button:hover {
          transform: translateY(-1px);
          box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
              0 4px 1px -2px rgba(0, 0, 0, 0.2),
              0 2px 5px 0 rgba(0, 0, 0, 0.12);
        }

        .primary {
          background: ${colors.primary};
          color: ${colors.blackText};
        }
        @media (min-width: 768px) {
          button {
            height: 46px;
            line-height: 46px;
          }
        }
      `}</style>
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  styling: PropTypes.oneOf(['primary', 'secondary', 'shout', 'info', 'transparent', 'transparent-dark', 'transparent-dark-small', 'default', 'bordered', 'light', 'disabled']),
  block: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'normal', 'xl']),
  shadow: PropTypes.bool,
  wide: PropTypes.bool,
  children: PropTypes.any
}

export default Button
