// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
const SENTRY_ENVIRONMENT = process.env.SENTRY_ENVIRONMENT || process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT

Sentry.init({
  dsn: SENTRY_DSN || 'https://154af6d229fd4c03a3d4b101e4cb00e0@o66794.ingest.sentry.io/1192839',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  release: process.env.NEXT_PUBLIC_RELEASE_NO,
  denyUrls: [
    /http:\/\/localhost:3000/i, // Dev
    /extensions\//i, // Chrome extensions
    /^chrome:\/\//i // Chrome extensions
  ],
  environment: SENTRY_ENVIRONMENT
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
})
