import React from 'react'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Footer from 'components/Footer'
import colors from 'styles/colors'
import GaPageTracker from 'components/GaPageTracker'

const Layout = (props) => (
  <div className='wrapper'>
    <Header />
    <main className='site-content container'>
      {props.children}
    </main>
    <Footer />
    <style jsx global>{`
    img,legend{border:0}legend,td,th{padding:0}html{font-family:sans-serif;-ms-text-size-adjust:100%;-webkit-text-size-adjust:100%}body{margin:0}article,aside,details,figcaption,figure,footer,header,hgroup,main,menu,nav,section,summary{display:block}audio,canvas,progress,video{display:inline-block;vertical-align:baseline}audio:not([controls]){display:none;height:0}[hidden],template{display:none}a{background-color:transparent}a:active,a:hover{outline:0}abbr[title]{border-bottom:1px dotted}b,optgroup,strong{font-weight:700}dfn{font-style:italic}h1{font-size:2em;margin:.67em 0}mark{background:#faebcc;color:#000}small{font-size:80%}sub,sup{font-size:75%;line-height:0;position:relative;vertical-align:baseline}sup{top:-.5em}sub{bottom:-.25em}svg:not(:root){overflow:hidden}figure{margin:1em 40px}hr{-moz-box-sizing:content-box;box-sizing:content-box;height:0}pre,textarea{overflow:auto}code,kbd,pre,samp{font-family:monospace,monospace;font-size:1em}button,input,optgroup,select,textarea{color:inherit;font:inherit;margin:0}button{overflow:visible}button,select{text-transform:none}button,html input[type=button],input[type=reset],input[type=submit]{-webkit-appearance:button;cursor:pointer}button[disabled],html input[disabled]{cursor:default}button::-moz-focus-inner,input::-moz-focus-inner{border:0;padding:0}input{line-height:normal}input[type=checkbox],input[type=radio]{box-sizing:border-box;padding:0}input[type=number]::-webkit-inner-spin-button,input[type=number]::-webkit-outer-spin-button{height:auto}input[type=search]{-webkit-appearance:textfield;-moz-box-sizing:content-box;-webkit-box-sizing:content-box;box-sizing:content-box}input[type=search]::-webkit-search-cancel-button,input[type=search]::-webkit-search-decoration{-webkit-appearance:none}fieldset{border:1px solid silver;margin:0 2px;padding:.35em .625em .75em}table{border-collapse:collapse;border-spacing:0}
    
    /* Grid
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
    .container {
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
      padding: 0;
      box-sizing: border-box; 
    }

    /* Base Styles
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
    html {
      font-size: 100%;
      height: 100%;
      box-sizing: border-box; 
    }
    body {
      font-size: 1em; /* currently ems cause chrome bug misinterpreting rems on body element */
      line-height: 1.6;
      font-weight: 400;
      font-style: normal;
      color: ${colors.blackText};
      height: 100%;
      background-color: ${colors.primary50};
      font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
      *,*:before,*:after {
        box-sizing: inherit;
    }
    .site-content {
      background-color: #fff;
      border-radius: .4rem;
      margin-bottom: 1rem;
      box-shadow: 0 1.2rem 3.6rem rgba(0, 0, 0, .2);
    }
    .content-main {
      padding: 1rem;
    }
    .wrapper {
      height: 100%;
    }
    .page-header {
      width: 100%;
      background-color: ${colors.primary};
      border-radius: .4rem .4rem 0 0;
      padding: 1rem;
    }
    .page-header h1 {
      color: #fff;
      margin: 0;
    }

    @media (max-width: 550px) {
      .content-main {
        padding: .5rem;
      }
    }

    @media (max-width: 1200px) {
      .site-content {
        border-radius: 0;
      }
    }
  
    /* Flexbox 
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
    body > div:first-child {
      height: 100%;
    }
    #__next {
      height: 100%;
    }
    #__next > div {
      height: 100%;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
    .site-content {
      flex: 1 0 auto;
    }

    /* Typography
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
    h1, h2, h3, h4, h5, h6 {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-weight: 400;
    }
    h1 { 
      font-size: 1.875rem; line-height: 1.25; font-weight: 700;
    }
    h2 { 
      font-size: 1.5rem; line-height: 1.15384615; font-weight: 700;
    }
    h3 { 
      font-size: 1.375rem; line-height: 1.13636364; font-weight: 700;
    }
    h4 { 
      font-size: 1.25rem; line-height: 1.22222222; font-weight: 700;
    }
    .page-container > h1,
    .page-container > h2,
    .page-container > h3,
    .page-container > h4 {
      text-align: left;
    }

    @media (min-width: 550px) {
      h1 { 
        font-size: 2.5rem; line-height: 1.05;
      }
      h2 {
        font-size: 2rem; line-height: 1.25; 
      }
      h3 { 
        font-size: 1.5rem; line-height: 1.25;
      }
      h4 {
        font-size: 1.5rem; line-height: 1.22222222;
      }
    }
    
    p {
      margin-top: 0; 
    }
    /* Links
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
    a {
      color: ${colors.link};
      text-decoration-skip: ink;
    }

    a:hover {
      text-decoration: none; 
    }

  /* Clearing
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
    
    /* Self Clearing Goodness */
    .container:after,
    .u-cf {
      content: "";
      display: table;
      clear: both; }
    
    
    /* Media Queries
    –––––––––––––––––––––––––––––––––––––––––––––––––– */
    
    /* Larger than mobile */
    @media (min-width: 400px) {}
    
    /* Larger than phablet (also point when grid becomes active) */
    @media (min-width: 550px) {}
    
    /* Larger than tablet */
    @media (min-width: 750px) {}
    
    /* Larger than desktop */
    @media (min-width: 1000px) {}
    
    /* Larger than Desktop HD */
    @media (min-width: 1200px) {}
    `}</style>
  </div>
)

Layout.propTypes = {
  children: PropTypes.node
}

export default GaPageTracker(Layout)
