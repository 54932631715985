import apiClient from 'utils/apiClient'
import { logError } from 'utils/logging'
import axios from 'axios'
import { getSearchableLanguages, adjustStoredUserLevels } from 'utils/utils'
const defaultSearchableLanguages = ['nb', 'nn', 'en', 'fr', 'es', 'de']
const getDefaultUserLevels = (searchableLanguages) => searchableLanguages.map(mnemonic => ({ mnemonic, level: 1 }))

const userInitialState = {
  level: 1,
  userLevels: getDefaultUserLevels(defaultSearchableLanguages),
  nynorsk: false,
  user: {},
  hasAccess: false,
  searchableLanguages: defaultSearchableLanguages,
  userStacks: [],
  loadedUserStacks: false,
  hasUpdatedUserLevels: true,
  userLevelUpdateMessage: ''
}

export const actionTypes = {
  LOAD_USER: 'LOAD_USER',
  CHANGE_USER_LEVEL: 'CHANGE_USER_LEVEL',
  CHANGE_USER_LEVEL_UPDATE_STATUS: 'CHANGE_USER_LEVEL_UPDATE_STATUS',
  CHANGE_LANGUAGE_VARIANT: 'CHANGE_LANGUAGE_VARIANT',
  GET_USER_STACKS: 'GET_USER_STACKS'
}

export default function reducer (state = userInitialState, action) {
  switch (action.type) {
    case actionTypes.LOAD_USER:
      return {
        ...state,
        user: { ...action.user },
        userLevels: action.user.loggedInUser && action.user.loggedInUser.userLevels.length > 0 ? adjustStoredUserLevels(action.user.loggedInUser.userLevels, action.user.languages) : getDefaultUserLevels(getSearchableLanguages(action.user.languages)),
        nynorsk: action.user.loggedInUser && action.user.loggedInUser.nynorsk,
        hasAccess: action.user.languages && action.user.languages.some(language => language.publications && language.publications.length > 0),
        searchableLanguages: getSearchableLanguages(action.user.languages)
      }
    case actionTypes.CHANGE_USER_LEVEL:
      return {
        ...state,
        userLevels: state.userLevels.map(userLevel => userLevel.mnemonic === action.userLevel.mnemonic ? action.userLevel : userLevel),
        hasUpdatedUserLevels: false,
        userLevelUpdateMessage: action.message
      }
    case actionTypes.CHANGE_USER_LEVEL_UPDATE_STATUS:
      return {
        ...state,
        hasUpdatedUserLevels: action.payload.status,
        userLevelUpdateMessage: action.payload.message
      }
    case actionTypes.CHANGE_LANGUAGE_VARIANT:
      return Object.assign({}, state, {
        nynorsk: action.nynorsk
      })
    case actionTypes.GET_USER_STACKS:
      return {
        ...state,
        userStacks: action.stacks,
        loadedUserStacks: true
      }
    default: return state
  }
}

export const loadUser = (headers, redirectFromTibet, accessKey, isServer) => dispatch => {
  if (!isServer && !redirectFromTibet) {
    return
  }
  return apiClient.get('/user', {
    params: {
      redirectFromTibet,
      accessKey
    },
    headers: { ...headers }
  })
    .then(({ data }) => {
      dispatch({
        type: actionTypes.LOAD_USER,
        user: data
      })
    })
    .catch((error) => {
      logError(error, 'redux/user/loadUser')
    })
}

export const changeUserLevel = (userLevel) => dispatch => {
  return dispatch({ type: actionTypes.CHANGE_USER_LEVEL, userLevel })
}

export const changeUserLevelUpdateStatus = (status, message) => dispatch => {
  return dispatch({ type: actionTypes.CHANGE_USER_LEVEL_UPDATE_STATUS, payload: { status, message } })
}

export const changeLanguageVariant = (nynorsk) => dispatch => {
  return dispatch({ type: actionTypes.CHANGE_LANGUAGE_VARIANT, nynorsk })
}

export const setSelection = (selection = null) => dispatch => {
  return dispatch({ type: actionTypes.SET_SELECTION, selection })
}

export const getUserStacks = (userId) => dispatch => {
  const url = 'https://www.puggekort.no/api/v1/user_stacks'
  return axios.get(url, {
    params: {
      userId: userId
    }
  })
    .then(({ data }) => {
      dispatch({
        type: actionTypes.GET_USER_STACKS,
        stacks: data.stacks
      })
    })
    .catch((error) => {
      logError(error, 'redux/user/getUserStacks')
    })
}
