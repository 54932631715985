import PropTypes from 'prop-types'

const LogoGyldendal = (props) => {
  const { color1 = '#e50043', color2 = '#485156', styles = {} } = props
  return (
    <svg
      style={styles}
      width='100%'
      height='100%'
      viewBox='0 0 539.28 119.44'
      fillRule='evenodd'
      clipRule='evenodd'
      strokeLinejoin='round'
      strokeMiterlimit='2'>
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <path style={{ fill: color1, fillRule: 'nonzero' }} d='M12.12,95.64a30.78,30.78,0,0,1-11.54-4l-.22-.14A.76.76,0,0,1,0,91a.77.77,0,0,1,.56-.93,73.21,73.21,0,0,0,18.87-7.17A73.22,73.22,0,0,0,34.87,71.61a31.76,31.76,0,0,1-20-6.15l-.25-.18a.78.78,0,0,1-.29-.42.77.77,0,0,1,.54-.93,42.07,42.07,0,0,0,16.25-8A42.3,42.3,0,0,0,41.75,43.2a20.5,20.5,0,0,1-8-1.36,20,20,0,0,1-4.57-2.51l-.1-.07a1,1,0,0,1-.25-.28.88.88,0,0,1,.34-1.18c4.76-2.65,9.57-5.45,12.49-9.67,5-6.74,6.83-19.22,7.51-27a1.06,1.06,0,1,1,2.12,0c.68,7.81,2.52,20.29,7.5,27,2.92,4.22,7.58,7,12.34,9.67A.87.87,0,0,1,71.48,39a.84.84,0,0,1-.25.28l-.09.07A20.13,20.13,0,0,1,58.57,43.2,34.51,34.51,0,0,0,81.75,58.69a.72.72,0,0,1,.59.83.74.74,0,0,1-.28.47l-.32.22A29.75,29.75,0,0,1,74,64.31,30.21,30.21,0,0,1,62.76,66a58.51,58.51,0,0,0,13.77,8.84,62.69,62.69,0,0,0,15.82,4.74.72.72,0,0,1,.6.83.73.73,0,0,1-.29.47l-.31.22a39.21,39.21,0,0,1-24.7,7.61l0,.2,17.77,7.41a.9.9,0,0,1,.48,1.19.94.94,0,0,1-.48.48l-.18.1a31.61,31.61,0,0,1-2.94,1.36,30.9,30.9,0,0,1-3.24,1.09c.78.86,1.57,1.73,2.4,2.61a138.76,138.76,0,0,0,11,10.47.86.86,0,0,1,.1,1.21.89.89,0,0,1-.57.3,51.74,51.74,0,0,1-15-1,53.2,53.2,0,0,1-12.66-4.48,36.82,36.82,0,0,0,4,8.49.86.86,0,0,1-.91,1.31A59.84,59.84,0,0,1,51.18,112a61.81,61.81,0,0,1-13.59-11.91,29,29,0,0,0,.63,4.22,29.59,29.59,0,0,0,1.41,4.44,52.49,52.49,0,0,1-14.25,5.33,51.77,51.77,0,0,1-14.95,1,.87.87,0,0,1-.57-.3.86.86,0,0,1,.09-1.21,137.06,137.06,0,0,0,11-10.47c3.22-3.42,6-6.76,8.5-9.93l0-.23a50.61,50.61,0,0,1-7.84,4.19,51.2,51.2,0,0,1-10.84,3.28,1,1,0,0,1-.73-.13A1,1,0,0,1,9.79,99Z'/>
          <path style={{ fill: color2, fillRule: 'nonzero' }} d='M160.81,55.2a53.53,53.53,0,0,0-16.1-2.41c-15.46,0-21.76,6.6-21.76,22.8,0,16.79,6,22.74,22.82,22.74a55.86,55.86,0,0,0,12.31-1.42c3.28-1,3.83-2.67,3.83-6V74.39A1.32,1.32,0,0,0,160.49,73H145.91a1.28,1.28,0,0,0-1.38,1.23v4.48A1.34,1.34,0,0,0,145.84,80l6.65.81v9.82a36.18,36.18,0,0,1-6.41.61c-10.35,0-13.34-3.47-13.34-15.47s3.13-15.56,13.39-15.56a47.88,47.88,0,0,1,11.81,1.61l1.22.3-.11,0,.79.1a1.35,1.35,0,0,0,1.41-1.12l.83-3.66s.08-.58.08-.58C162.16,55.83,161.49,55.48,160.81,55.2Z'/>
          <path style={{ fill: color2, fillRule: 'nonzero' }} d='M209.58,53.54h-7.53a1.67,1.67,0,0,0-1.65,1.09s-8.49,14.46-10.57,18c-.87-1.42-10.92-18-10.92-18a2,2,0,0,0-1.77-1.07h-7.76A1.58,1.58,0,0,0,168,55l.18.64h0l16.58,25V96.17a1.41,1.41,0,0,0,1.49,1.4h6.58a1.43,1.43,0,0,0,1.55-1.4V80.56l16.38-25,0-.16.06-.42A1.34,1.34,0,0,0,209.58,53.54Z'/>
          <path style={{ fill: color2, fillRule: 'nonzero' }} d='M248.32,89.94H227.86V54.76a1.33,1.33,0,0,0-1.32-1.34l-6.9,1.06h0c-1.23.15-1.37,1-1.37,1.34V96.17a1.41,1.41,0,0,0,1.49,1.4h28.6a1.46,1.46,0,0,0,1.49-1.46V91.4A1.46,1.46,0,0,0,248.32,89.94Z'/>
          <path style={{ fill: color2, fillRule: 'nonzero' }} d='M276.86,53.54h-17.8A1.45,1.45,0,0,0,257.58,55V96.11a1.45,1.45,0,0,0,1.48,1.46h17.8c15,0,20.64-6,20.64-22S291.9,53.54,276.86,53.54Zm-1,36.32-8.65.06V60.59h8.65c8.75,0,11.86,2.25,11.86,14.88C287.7,88.38,284.21,89.86,275.84,89.86Z'/>
          <path style={{ fill: color2, fillRule: 'nonzero' }} d='M337.88,89.93H317V78.61h20a1.43,1.43,0,0,0,1.47-1.4V72.62a1.45,1.45,0,0,0-1.47-1.46H317V60.94h20.87a1.42,1.42,0,0,0,1.49-1.46V54.94a1.41,1.41,0,0,0-1.49-1.4H308.94A1.52,1.52,0,0,0,307.39,55V96.11a1.52,1.52,0,0,0,1.55,1.46h28.94a1.46,1.46,0,0,0,1.49-1.46V91.33A1.45,1.45,0,0,0,337.88,89.93Z'/>
          <path style={{ fill: color2, fillRule: 'nonzero' }} d='M424.84,53.54H407A1.46,1.46,0,0,0,405.56,55V96.11A1.46,1.46,0,0,0,407,97.57h17.8c15,0,20.63-6,20.63-22S439.88,53.54,424.84,53.54Zm-1,36.39-8.65.06V60.59h8.65c8.74,0,11.85,2.25,11.85,14.88C435.68,88.38,432.19,89.93,423.83,89.93Z'/>
          <path style={{ fill: color2, fillRule: 'nonzero' }} d='M537.78,89.94H517.32V54.76A1.33,1.33,0,0,0,516,53.42l-6.91,1.06h0a1.65,1.65,0,0,0-1.39,1.88V96.17a1.42,1.42,0,0,0,1.5,1.4h28.59a1.46,1.46,0,0,0,1.5-1.46V91.4A1.46,1.46,0,0,0,537.78,89.94Z'/>
          <path style={{ fill: color2, fillRule: 'nonzero' }} d='M360.28,67c.35.85,20.66,29.27,20.66,29.27a2.7,2.7,0,0,0,2.51,1.36h7a1.6,1.6,0,0,0,1.7-1.58V55.2c0-.38-.13-1.63-1.88-1.63l-6,1.06c-2,.37-1.87,1.67-1.87,2.16,0,0,.25,19.44.32,26.61-3.64-5.25-19.95-28.57-19.95-28.57A2.34,2.34,0,0,0,361,53.57h-8.2a1.67,1.67,0,0,0-1.69,1.57V96a1.58,1.58,0,0,0,1.64,1.58H359A1.6,1.6,0,0,0,360.69,96Z'/>
          <path style={{ fill: color2, fillRule: 'nonzero' }} d='M499,95.28,484.07,57.76c-1.3-3.37-4.45-5.15-9.09-5.15h-.14c-4.64,0-7.78,1.78-9.09,5.15L450.8,95.28a2.79,2.79,0,0,0-.16.77c0,1.1,1.08,1.66,3.19,1.66h4.51a2.74,2.74,0,0,0,2.56-1.64s3.28-8.56,3.53-9.2h21c.24.64,3.52,9.2,3.52,9.2a2.77,2.77,0,0,0,2.56,1.64H496c2.11,0,3.17-.56,3.17-1.66A3,3,0,0,0,499,95.28Zm-31.92-15.9c1.21-3.38,6.82-18.13,6.82-18.13.2-.53.51-.55,1-.55h0c.48,0,.79,0,1,.55,0,0,5.61,14.75,6.81,18.13Z'/>
        </g>
      </g>
    </svg>
  )
}

LogoGyldendal.propTypes = {
  color1: PropTypes.string,
  color2: PropTypes.string,
  styles: PropTypes.object
}

export default LogoGyldendal
