import apiClient from 'utils/apiClient'
import { logError } from './logging'

export const persistUserLevels = async (userLevels, headers) => {
  const userLevelsToPost = userLevels.some(ul => ul.level > 1) ? JSON.stringify(userLevels) : '[]'
  const body = { userLevels: userLevelsToPost }
  try {
    await apiClient.post('/user/userlevels',
      body,
      {
        headers: { ...headers }
      })
    return true
  } catch (error) {
    logError(error, 'utils/apiCalls/persistUserLevels')
    return false
  }
}

export const persistNynorsk = async (nynorsk, headers) => {
  const body = { checked: nynorsk }
  try {
    await apiClient.post('/user/nynorsk',
      body,
      {
        headers: { ...headers }
      })
    return true
  } catch (error) {
    logError(error, 'utils/apiCalls/persistNynorsk')
    return false
  }
}

export const fetchSuggestions = async (phrase, lang) => {
  const result = await apiClient.get('/search/suggestions', {
    params: {
      lang,
      phrase
    }
  })
  return result.data
}
