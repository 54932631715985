import React from 'react'
import Router from 'next/router'
import { connect } from 'react-redux'
import colors from 'styles/colors'
import PropTypes from 'prop-types'
import config from 'config'
import Autosuggest from 'react-autosuggest'
import { fetchSuggestions } from 'utils/apiCalls'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'
import KeyboardIcon from '@mui/icons-material/Keyboard'
import { bindActionCreators } from 'redux'
import { setSearchPhrase } from 'redux/reducers/search'
import cookie from 'cookie'
import { getLanguage, getPossibleLanguages } from 'utils/utils'

class SearchForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      useLang: this.props.lang || 'en',
      showLangs: false,
      showKeyboard: false,
      suggestions: []
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('mousedown', this.handleClickOutside)
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node
  }

  handleClickOutside = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.showLangs) {
        this.setState({
          showLangs: false
        })
      }
    }
  }

  handleSubmit = (event) => {
    event && event.preventDefault()
    this.performSearch(this.props.searchPhrase)
  }

  performSearch = (searchPhrase, lang) => {
    const phrase = searchPhrase ? encodeURIComponent(searchPhrase.trim()) : ''
    const searchlang = lang || this.state.useLang
    if (phrase) {
      Router.push(`/search?phrase=${phrase}&lang=${searchlang}`)
    }
  }

  handleToggleLang = () => {
    this.setState({
      showLangs: !this.state.showLangs
    })
  }

  chooseLang = (lang) => {
    this.setState({
      useLang: lang,
      showLangs: false
    })
    document.cookie = cookie.serialize('skole_lang', lang, { maxAge: 365 * 24 * 60 * 60 * 1000 })
    this.performSearch(this.props.searchPhrase, lang)
  }

  getPlacehoder = () => {
    const langSetting = config.languages.find(langSetting => langSetting.mnemonic === this.state.useLang)
    return langSetting.mnemonic === 'nb' && this.props.nynorsk ? langSetting.placeholder + ' eller nynorsk' : langSetting.placeholder
  }

  handleSuggestionSelected = (event, { suggestionValue }) => {
    this.performSearch(suggestionValue)
  }

  escapeRegexCharacters = (str) => {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
  }

  handleSuggestionsFetchRequested = ({ value }) => {
    const escapedValue = this.escapeRegexCharacters(value.trim())
    const { hasAccess } = this.props
    if (escapedValue !== '' && escapedValue.length >= config.minQuerySuggestions && hasAccess) {
      if (this.fetchSuggestionsTimeout) {
        window.clearTimeout(this.fetchSuggestionsTimeout)
        this.fetchSuggestionsTimeout = undefined
      }
      this.fetchSuggestionsTimeout = setTimeout(() => {
        fetchSuggestions(escapedValue, this.state.useLang).then(suggestions => this.setState({
          suggestions
        }))
      }, 300)
    }
  }

  handleSuggestionReset = () => {
    this.setState({
      suggestions: []
    })
  }

  onRenderSuggestion = (suggestion) => {
    return <SuggestionItem phrase={this.props.searchPhrase} suggestion={suggestion} />
  }

  onShouldRenderSuggestions = (value) => {
    // return !!(this.props.shouldRenderSuggestion || value !== this.props.phrase)
    return true
  }

  onInputChange = (event, { newValue }) => {
    this.props.setSearchPhrase(newValue)
  }

  handleClearSearch = (e) => {
    e.preventDefault()
    this.props.setSearchPhrase('')
  }

  handleToggleKeyboard = (e) => {
    e.preventDefault()
    this.setState({
      showKeyboard: !this.state.showKeyboard
    })
  }

  addChar = (char) => {
    this.props.setSearchPhrase(this.props.searchPhrase + char)
    this.input && this.input.focus()
  }

  render () {
    return (
      <div className='searchbox'>
        <form onSubmit={this.handleSubmit}>
          <div className='wrapper-wrapper'>
            <div className='options-wrapper' ref={this.setWrapperRef}>
              <button className='searchbox-language-button' onClick={this.handleToggleLang} type='button' aria-label='Språkvelger'>
                <div>
                  <img src={`/img/flags/${this.state.useLang}.svg`} className='searchbox-language' alt='' />
                  <span className='lang-name'>{getLanguage(this.state.useLang).name}</span>
                  <img src='/img/arrow-down.svg' className='expand' alt='' />
                </div>
              </button>
              {this.state.showLangs && this.renderFlagDropdown()}
            </div>
            <div className='searchbox-wrapper'>
              {this.renderInput()}
              {this.props.searchPhrase && <button type='button' className='searchbox-clear-button' onClick={this.handleClearSearch} aria-label='Tøm søkefeltet'><ClearIcon className='clearIcon' /></button>}
              <button type='button' className='searchbox-keyboard-button' onClick={this.handleToggleKeyboard} aria-label='Vis tastatur med spesialtegn'><KeyboardIcon className='keyboardIcon' /></button>
              {this.state.showKeyboard && this.renderKeyboard()}
              <button className='searchbox-submit-button' type='submit' aria-label='Søk'><SearchIcon className='searchIcon' alt='Søk' /></button>
            </div>
          </div>
        </form>
        <style>{`
          .searchIcon {
            height: 2.4rem;
            width: 2.4rem;
            color: #fff;
            border: 0;
          }
          .clearIcon {
            height: 2rem;
            width: 2rem;
            color: ${colors.secondary};
          }
          .keyboardIcon {
            height: 2rem;
            width: 2rem;
            color: ${colors.secondary};
          }
          @media (max-width: 550px) {
            .clearIcon {
              height: 1.2rem;
              width: 1.2rem;
            }
            .keyboardIcon {
              height: 1.2rem;
              width: 1.2rem;
            }
            .searchIcon {
              height: 2rem;
              width: 2rem;
            }
          }
        `}</style>
        <style jsx>{`
          .searchbox {
            padding: 0;
            margin: .6rem 0 0 1.2rem;
            position: relative;
            width: 100%;
          }
          .wrapper-wrapper {
            display: flex;
            background-color: #fff;
            border: 1px solid ${colors.secondary};
            /*border-radius: .2rem;*/
          }
          .searchbox-wrapper {
            display: flex;
            width: 100%;
            /*border-radius: 0 .2rem .2rem 0;*/
          }
          .options-wrapper {
            border-radius: .2rem 0 0 .2rem;
            border-right: 1px solid ${colors.secondary};
          }
          .searchbox-submit-button {
            height: 2.8rem;
            background-color: ${colors.secondary};
            line-height: 1rem;
            font-size: 1rem;
            padding-top: .2rem;
            border-color: ${colors.secondary};
            /*border-radius: 0 .2rem .2rem 0;*/
            border-radius: 0;
          }
          .searchbox-submit-button:focus {
            background-color: ${colors.secondary400};
            border-color: ${colors.secondary400};
          }
          .searchbox-clear-button {
            height: 2.7rem;
            border-radius: 0;
            border: none;
            border-bottom: .2rem solid #fff;
            margin: 0;
            padding: .4rem 0 0 0;
            background-color: #fff;
            line-height: 1rem;
            font-size: 1rem;
          }
          .searchbox-clear-button:focus {
            border-bottom: .2rem solid ${colors.primary};
          }
          .searchbox-keyboard-button {
            height: 2.7rem;
            border-radius: 0;
            border: none;
            border-bottom: .2rem solid #fff;
            padding: .4rem .5rem 0 .5rem;
            background-color: #fff;
            line-height: 1rem;
            font-size: 1rem;
          }
          .searchbox-keyboard-button:focus {
            border-bottom: .2rem solid ${colors.primary};
          }
          .searchbox-language {
            height: 2rem;
            box-shadow: 0 1px 2px rgba(0,0,0,.3);
          }
          .searchbox-language-button {
            background-color: #fff;
            border: none;
            border-radius: .2rem;
            height: 2.7rem;
            line-height: 1.5rem;
            font-size: 1.5rem;
            padding: 0 .5rem;
            width: 13rem;
            text-align:left;
            vertical-align: middle;
          }
          .searchbox-language-button:focus {
            outline: ${colors.primary} solid medium;
          }
          .searchbox-language-button img {
            border-radius: .2rem;
            vertical-align: middle;
          }
          .searchbox-language-button span {
            vertical-align: middle;
            color: #000;
            text-transform: none;
            margin-bottom: 1rem;
            height: 100%;
            margin-left: 1rem;
          }
          .searchbox-language-button div {
            vertical-align: middle;
          }
          .expand {
            height: .8rem;
            width: .8rem;
            vertical-align: middle;
            float: right;
            margin-top: .7rem;
            border-radius: 0 !important;
          }
          @media (max-width: 750px) {
            .lang-name {
              display: none;
            }
            .searchbox-language-button {
              width: 6rem;
            }
          }
          @media (max-width: 550px) {
            .searchbox {
              margin: 0;
            }
            .searchbox-submit-button {
              padding-right: .2rem;
              padding-left: .2rem;
            }
            .searchbox-language {
              height: 1.6rem;
            }
            .searchbox-language-button {
              width: 4.4rem;
              padding: 0 .5rem .1rem .5rem;
            }
            .expand {
              height: .6rem;
              width: .6rem;
            }
            .searchbox-clear-button {
              height: 2.75rem;
            }
            .searchbox-keyboard-button {
              height: 2.70rem;
              padding: .4rem .2rem 0 .2rem;
            }
            .searchbox-language-button {
              height: 2.7rem;
            }
            .searchbox-submit-button {
              height: 2.75rem;
            }
          }
        `}</style>
      </div>
    )
  }

  renderInput () {
    return <div className='itemFill suggestionContainer'>
      <Autosuggest
        id='search-input'
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionReset}
        getSuggestionValue={(suggestion) => suggestion}
        onSuggestionSelected={this.handleSuggestionSelected}
        renderSuggestion={this.onRenderSuggestion}
        shouldRenderSuggestions={this.onShouldRenderSuggestions}
        focusInputOnSuggestionClick
        inputProps={{
          placeholder: this.getPlacehoder(),
          value: this.props.searchPhrase,
          name: 'phrase',
          onChange: this.onInputChange,
          className: 'input',
          title: this.getPlacehoder(),
          onFocus: this.onInputFocus,
          id: 'search-input',
          'aria-label': 'Søkeboks'
        }}
        ref={autosuggest => {
          if (autosuggest !== null && !this.input) {
            this.input = autosuggest.input
          }
        }}
      />
      <style>{`
        .itemFill {
          flex-grow: 1;
        }
        .suggestionContainer {
          position: relative;
          width: 85%;
        }
        .suggestionContainer ul {
          position: absolute;
          top: 2.8rem;
          width: 80%;
          margin: 0;
          padding: 0;
          list-style-type: none;
          border: 1px solid LightGray;
          background-color: #fff;
          font-weight: 300;
          z-index: 2;
          border-radius: .4rem;
        }
        .suggestionContainer li {
          cursor: pointer;
        }
        .suggestionContainer li:not(:first-child) {
          border-top: 1px solid LightGray;
        }
        .suggestionContainer li .react-autosuggest__suggestion--focused {
            background-color: LightGray;
        }
        .input {
          vertical-align: top;
          height: 2.7rem;
          padding: .5rem .8rem .5rem .9rem;
          font-size: 1.5rem;
          background-color: #fff;
          border: none;
          box-shadow: none;
          box-sizing: border-box;
          border-radius: 0; 
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          width: 100%;
        }
        @media (max-width: 550px) {
          .input {
            font-size: 1.1rem;
            padding: .2rem;
            height: 2.7rem;
          }
        }
      `}</style>
    </div>
  }

  renderFlagDropdown () {
    const languages = this.props.hasAccess ? this.props.searchableLanguages : getPossibleLanguages()
    return (
      <div className='flag-dropdown'>
        <div className='flag-dropdown-arrow-outer' />
        <div className='flag-dropdown-arrow-inner' />
        <div className='flag-dropdown-container'>
          <ul>
            {languages.filter(mnemonic => this.state.useLang !== mnemonic).map((mnemonic, key) => {
              const language = getLanguage(mnemonic)
              return <FlagDropdownElement key={key} mnemonic={language.mnemonic} name={language.name} flag={language.flag} chooseLang={this.chooseLang} />
            })}
          </ul>
        </div>
        <style jsx>{`
          .flag-dropdown {
            position: absolute;
            top: 3.6rem;
            width: 13rem;
            z-index: 3;
            background-color: #fff;
            border: 1px solid ${colors.secondary200};
            border-radius: 0;
          }
          .flag-dropdown-arrow-outer {
            position: absolute; 
            bottom: 100%; 
            left: 50%; 
            width: 0px; 
            height: 0px; 
            margin-left: -13px; 
            border-width: 12px; 
            border-style: solid; 
            border-color: rgba(193, 202, 211, 0) rgba(193, 202, 211, 0) rgba(193, 202, 211, 1); 
            pointer-events: none; 
            margin-top: -1px;
          }
          .flag-dropdown-arrow-inner {
            position: absolute; 
            bottom: 100%; 
            left: 50%; 
            width: 0px; 
            height: 0px; 
            margin-left: -11px; 
            border-width: 10px; 
            border-style: solid; 
            border-color: rgba(193, 202, 211, 0) rgba(193, 202, 211, 0) rgb(255, 255, 255); 
            pointer-events: none; 
            margin-top: -1px;
          }
          ul {
            list-style: none;
            padding-left: 0;
          }
          `}</style>
      </div>
    )
  }

  renderKeyboard () {
    const keyboardChars = {
      nb: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'œ', 'ß', 'ú', 'ù', 'û', 'ÿ', 'ü', 'ä', 'ö', 'æ', 'ø', 'å'],
      nn: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'œ', 'ß', 'ú', 'ù', 'û', 'ÿ', 'ü', 'ä', 'ö', 'æ', 'ø', 'å'],
      en: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'œ', 'ß', 'ú', 'ù', 'û', 'ÿ', 'ü', 'ä', 'ö', 'æ', 'ø', 'å'],
      po: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'œ', 'ß', 'ú', 'ù', 'û', 'ÿ', 'ü', 'ä', 'ö', 'æ', 'ø', 'å'],
      es: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'œ', 'ß', 'ú', 'ù', 'û', 'ÿ', 'ü', 'ä', 'ö', 'æ', 'ø', 'å'],
      de: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'œ', 'ß', 'ú', 'ù', 'û', 'ÿ', 'ü', 'ä', 'ö', 'æ', 'ø', 'å'],
      sv: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'œ', 'ß', 'ú', 'ù', 'û', 'ÿ', 'ü', 'ä', 'ö', 'æ', 'ø', 'å'],
      it: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'œ', 'ß', 'ú', 'ù', 'û', 'ÿ', 'ü', 'ä', 'ö', 'æ', 'ø', 'å'],
      fr: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'œ', 'ß', 'ú', 'ù', 'û', 'ÿ', 'ü', 'ä', 'ö', 'æ', 'ø', 'å'],
      ru: ['а', 'б', 'в', 'г', 'д', 'е', 'ж', 'з', 'и', 'й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т', 'у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ъ', 'ы', 'ь', 'э', 'ю', 'я', 'æ', 'ø', 'å'],
      zh: ['ā', 'ē', 'ī', 'ō', 'ū', 'á', 'é', 'í', 'ó', 'ú', 'ǎ', 'ě', 'ǐ', 'ǒ', 'ǔ', 'à', 'è', 'ì', 'ò', 'ù'],
      ar: ['ا', 'ؤ', 'ء', 'ب', 'ت', 'ث', 'ج', 'ح', 'خ', 'د', 'ذ', 'ر', 'ز', 'س', 'ش', 'ص', 'ض', 'ط', 'ظ', 'ع', 'غ', 'ف', 'ق', 'ك', 'ل', 'م', 'ن', 'ه', 'ة', 'و', 'ي', 'ى', 'ئ'],
      pl: ['à', 'â', 'ç', 'é', 'è', 'ê', 'ë', 'í', 'ì', 'î', 'ï', 'ñ', 'ó', 'ò', 'ô', 'œ', 'ß', 'ú', 'ù', 'û', 'ÿ', 'ü', 'ä', 'ö', 'æ', 'ø', 'å']
    }
    return (
      <div className='keyboard-wrapper'>
        <div className='keyboard-arrow-outer' />
        <div className='keyboard-arrow-inner' />
        {keyboardChars[this.state.useLang].map((char, key) => {
          return (
            <button type='button' key={key} className='character-button' onClick={(e) => this.addChar(char)}>{char}</button>
          )
        })}
        <style jsx>{`
          .keyboard-wrapper {
            position: absolute;
            right: 2rem;
            top: 2.8rem;
            background-color: #fff;
            border: 1px solid ${colors.secondary200};
            border-radius: .2rem;
            width: 12rem;
            z-index: 2;
            padding-top: .4rem;
          }
          .keyboard-arrow-outer {
            position: absolute; 
            bottom: 100%; 
            left: 50%; 
            width: 0px; 
            height: 0px; 
            margin-left: -13px; 
            border-width: 12px; 
            border-style: solid; 
            border-color: rgba(193, 202, 211, 0) rgba(193, 202, 211, 0) rgba(193, 202, 211, 1); 
            pointer-events: none; 
            margin-top: -1px;
          }
          .keyboard-arrow-inner {
            position: absolute; 
            bottom: 100%; 
            left: 50%; 
            width: 0px; 
            height: 0px; 
            margin-left: -11px; 
            border-width: 10px; 
            border-style: solid; 
            border-color: rgba(193, 202, 211, 0) rgba(193, 202, 211, 0) rgb(255, 255, 255); 
            pointer-events: none; 
            margin-top: -1px;
          }
          .character-button {
            width: 2.3rem;
            border: none;
            padding: 0;
            text-transform: none;
            font-size: 1rem;
            line-height: 1rem;
          }
        `}</style>
      </div>
    )
  }
}

const SuggestionItem = ({ phrase, suggestion }) => {
  return (
    <div className={`suggestionItem ${phrase === suggestion ? 'suggestionItemSelected' : ''}`}>
      {suggestion}
      <style jsx>{`
      .suggestionItem {
        padding: 0.2rem 0.2rem 0.2rem 0.5rem;
        font-size: 1.5rem;
      }
      .suggestionItemSelected {
        background: ${colors.highlight};
      }
      `}</style>
    </div>
  )
}

SuggestionItem.propTypes = {
  phrase: PropTypes.string,
  suggestion: PropTypes.string
}

SearchForm.propTypes = {
  searchPhrase: PropTypes.string,
  lang: PropTypes.string,
  setSearchPhrase: PropTypes.func,
  searchableLanguages: PropTypes.array,
  nynorsk: PropTypes.bool,
  hasAccess: PropTypes.bool
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchPhrase: bindActionCreators(setSearchPhrase, dispatch)
  }
}
const mapStateToProps = ({ search: { searchPhrase, lang }, user: { searchableLanguages, nynorsk, hasAccess } }) => ({ searchPhrase, lang, searchableLanguages, nynorsk, hasAccess })
export default connect(mapStateToProps, mapDispatchToProps)(SearchForm)

const FlagDropdownElement = (props) => {
  const { mnemonic, name, flag, chooseLang } = props
  return (
    <li>
      <button className='flag-dropdown-button' onClick={() => chooseLang(mnemonic)} aria-label={name}>
        <div>
          <img src={flag} className='flag-dropdown-flag' alt={'Velg ' + name.toLowerCase} />
          <span>{name}</span>
        </div>
      </button>
      <style jsx>{`
        .flag-dropdown-button {
          height: 2.8rem;
          line-height: 1.5rem;
          font-size: 1.5rem;
          padding: 0 1rem;
          width: 12.9rem;
          text-align:left;
          border: none;
          border-radius: 0;
          background-color: #fff;
        }
        .flag-dropdown-button:focus, .flag-dropdown-button:hover {
          background-color: ${colors.primary100};
        }
        .flag-dropdown-button div {
          vertical-align: middle;
          display: inline;
        }
        .flag-dropdown-button img {
          border-radius: .2rem;
          vertical-align: middle;
          display: inline;
        }
        .flag-dropdown-button span {
          vertical-align: middle;
          color: #000;
          text-transform: none;
          margin-bottom: 1rem;
          height: 100%;
          margin-left: 1rem;
          display: inline;
        }
        .flag-dropdown-flag {
          height: 2rem;
          box-shadow: 0 1px 2px rgba(0,0,0,.3);
        }
        .flag-dropdown-flag-no {
          margin-right: .5rem;
        }
        button {
          border: none;
        }
        li {
          margin-bottom: .5rem;
        }
      `}</style>
    </li>
  )
}

FlagDropdownElement.propTypes = {
  mnemonic: PropTypes.string,
  name: PropTypes.string,
  flag: PropTypes.string,
  chooseLang: PropTypes.func
}
